"use client";

import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import { analytics } from "lib/segment";

const BLOCKED_IPS = [
  "64.29.134.134",
  "203.126.165.78",
  "64.29.135.223",
  "76.9.210.94",
  "64.29.128.238",
  "190.103.176.215",
  "207.7.140.232",
  "77.246.180.158",
  "5.254.100.22",
  "15.254.38.106",
  "80.239.156.96",
  "4.53.29.77",
  "104.166.169.70",
  "23.90.171.74",
  "15.181.176.225",
  "4.228.82.142",
  "20.118.170.138",
  "34.176.22.34",
  "16.162.197.133",
  "41.198.131.167",
  "13.39.2.166",
  "20.94.198.181",
  "115.240.143.68",
  "64.29.129.197",
  "223.70.241.7",
  "94.46.130.85",
  "4.59.33.81",
  "4.37.0.153",
  "4.37.0.149",
  "4.37.0.152",
  "4.59.33.67",
];
export default function Analytics() {
  const pathname = usePathname();
  const [isBlocked, setIsBlocked] = useState(true);

  useEffect(() => {
    async function fetchIP() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        if (BLOCKED_IPS.includes(data.ip)) {
          setIsBlocked(true);
        } else {
          setIsBlocked(false);
        }
      } catch (error) {
        console.error("Failed to fetch IP address:", error);
      }
    }

    fetchIP();
  }, []);

  useEffect(() => {
    if (!isBlocked) {
      analytics.page();
      console.log("segment loaded");
    }
  }, [pathname, isBlocked]);

  return null;
}
